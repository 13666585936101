export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/pinocchios-logo.png',
    banner_img_root : '/img/pinocchios-banner.png',
    resid : '',
    key_value : 'loscomalesgreeley',
    secret_value : 'loscomalesgreeley',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12149.210913138182!2d-104.7014062!3d40.4242951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876ea2326f636781%3A0xfbc11fbd18b0837c!2sTaqueria%20Los%20Comales!5e0!3m2!1sen!2sin!4v1676546897353!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Los Comales Order Online"

};
